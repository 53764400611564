import React from "react";

export const UserGuide = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 1734.2 1701.3"
      version="1.1"
      viewBox="0 0 1734.2 1701.3"
      xmlSpace="preserve"
      width={props.width}
      height={props.height}
      className={props.className}
    >
      <path d="M964.2 1306.1c-2.9 30.5-11.6 38.6-40.3 38.7-46.4.2-92.8.1-139.2 0-29.7 0-38.2-7.6-42.7-38.8H209.1c-30.7 0-35.5-4.7-35.6-34.9V602.5c0-36.9.6-37.5 37.3-39 2.6-.1 5.3-.7 9.8-1.3 0-24.3.3-48.1-.1-71.8-.4-19.9 6.9-32.1 28.3-33.9 18.8-1.6 37.5-5.5 56.4-6.6 13-.7 18.3-4.4 18.5-18.5.8-48.9 1.3-49.6 51.1-54.4 173.1-16.8 329.3 24.8 464.7 136.3 10.4 8.5 17.3 9.4 28.4.2C978 421.9 1105.2 375.6 1248.3 374c34.3-.4 68.8 2.3 103.1 4.2 25 1.4 32.7 10.4 32.9 36.3.1 10.4 0 20.8 0 32.3 27.2 3.7 52.7 7.5 78.3 10.7 17.4 2.1 23.8 12.2 23.7 28.6-.2 25.2-.1 50.3-.1 74.6 3 1.5 4.2 2.5 5.4 2.5 42.5 1.8 42.9 2.2 43 44.9l.3 653.8c0 41.2-2.9 44.1-44.2 44.1H995.1c-9.7.1-19.5.1-30.9.1zm373.2-173.1V417.6c-53.1 2.3-105 2-156.3 7.2-106.8 10.7-200 55.1-283.5 121.5-10.9 8.7-15.3 17.5-15.3 31.7.5 219.4.3 438.8.4 658.2 0 5.8.8 11.6 1.4 19.4 135.7-102.2 286.6-138.8 453.3-122.6zm-514.6 122.2c.8-4.5 1.4-6.5 1.4-8.5.1-225 .2-450.1-.4-675.1 0-7.5-5.5-17.1-11.5-22.1-107.6-90.1-232.4-131.9-372.1-131.1-23.6.1-47.3 2.2-70.4 3.3v711.1c166.4-15.9 317.4 20.4 453 122.4zm-167.8-45c-4.4-1.8-8.8-3.8-13.3-5.4-88.5-31.4-179.8-34.7-272.1-25.9-40.7 3.9-46.2 0-46.2-40 0-209.5 0-419.1-.1-628.6 0-5.4-.5-10.8-.9-17.5-19.4 2.6-37.1 4.9-54.6 7.2v710.3c129.9-16 258.4-18.1 387.2-.1zM1384.4 493v647.7c0 35.4-5.8 39.8-40.1 39.1-56.7-1-113.8-3.5-170.2 1-41.6 3.3-82.4 16.7-123.6 25.6.5 2.4.9 4.8 1.4 7.2 128.7-21.9 257.6-19 387.5-3.7V499.6c-18-2.1-35.3-4.2-55-6.6z"></path>
    </svg>
  );
}

