import React from "react";

export const RO=(props)=> {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            enableBackground="new 0 0 2267.7 1884.2"
            version="1.1"
            viewBox="0 0 2267.7 1884.2"
            xmlSpace="preserve"
            width={props.width}
            height={props.height}
            className={props.className}
        >
            <path
                d="M874.1 12.8h32c2.4 1.5 4.7 3.2 7.2 4.6 21.9 12.1 43.9 23.9 65.6 36.3 34.2 19.6 66.8 42.9 102.6 58.9 70.1 31.2 142.3 57.8 213 87.7 39.3 16.6 74.3 40.7 105.1 70.6 13.7 13.4 26.5 29.5 43 37.9 26.8 13.6 55.6 25.5 84.9 31.7 76.8 16.1 148.1 2.9 209.9-49 15.2-12.8 34.1-16.6 51.9-8.9 19.7 8.5 38.9 19.3 56 32.3 41.1 31.1 83.9 58.3 132.9 75.5 14.7 5.2 30.2 12.3 41.1 22.9 26 25.2 45.1 56 59.9 89.1 7.5 16.6 14.6 33.4 22.2 50 14.3 30.8 23.7 63 26.7 96.7.8 9.1-1.6 19.6-5.7 27.9-6.5 13.2-15.7 25.1-23.7 37.6-18.1 28.2-35 57.2-54.5 84.5-31.8 44.5-63.8 88.9-98.4 131.2-25.5 31.1-53.8 60.3-83.6 87.4-48.9 44.4-102.3 82.9-160.8 114.5-65.1 35.2-133.6 60.9-205.1 79.5-54.8 14.2-110.3 26.3-164 44-92.2 30.5-168.9 86.1-232.4 158.9-39.9 45.7-72 97.2-97.6 152.2-16.2 34.9-30.2 70.8-45.3 106.2-6.4 15.1-12.3 30.5-20 44.9-17.1 32-38.3 61.2-67 84-26.6 21.2-53.6 42.2-81.8 61.1-19.3 13-57 8.8-73.3-7.7-19-19.4-35.9-40.9-53.2-61.9-9.8-11.9-17.1-26-27.9-36.7-39-38.2-87-56.1-141.1-59-46.7-2.6-93.1.3-139.2 8.1-9.4 1.6-19.4.2-31.1.2 3.5-10.6 9.5-19 7.9-25.7-3.8-15.8-9-31.9-17.4-45.7-15.8-26.2-10.9-46.5 17.4-57.5 20.6-8.1 41.4-15.7 62.8-23.7-3.4-11.4-7.7-21.3-9-31.6-2.9-22.6-1.1-44.5 6.2-67 11.1-34.2 30.3-63.4 50-92.3 20.8-30.5 43.7-59.4 65.1-89.4 4.7-6.6 7.3-14.8 11.3-23.1-28.8 11.3-54.9 23-81.9 31.7-21.6 6.9-35.9-1.5-43.3-22.6-3.9-11.2-6.9-22.9-8.7-34.7-1.5-10-5.9-15.7-15.4-18.6-13.5-4-26.9-8.1-40-13.2-36.8-14.4-70.9-33.5-96.3-64.5-24.9-30.4-38.5-66.7-38.2-105.5.3-51.2 4.9-102.4 8.7-153.5 3.1-40.9-.8-81.4-18.8-117.6-14.1-28.1-10.7-40.7 18.9-55.7 17.6-9 36.4-15.6 54-24.7 43.5-22.5 68.7-56.6 64.8-107.8-.5-6.8-.1-13.6-.6-20.4-1.9-27.9 10.3-47.6 33.5-62.6 16.5-10.6 31.9-23.3 47.1-35.8 47.7-39.1 63.7-92.7 65.3-151.2.6-23.3-5.6-46.7-8.5-70.1-2.8-22.1 7.8-37 24.5-50.2 28.2-22.2 61.2-33.2 94.9-41.9 55.8-14.5 112.4-25.8 168-40.6 50.1-13.5 95.8-35.9 129.4-77.7z"
                className="st0"
            ></path>
        </svg>
    );
}
