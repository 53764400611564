import React from "react";

export const LexisEntertainment=(props)=> {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            enableBackground="new 0 0 1908 1884.2"
            version="1.1"
            viewBox="0 0 1908 1884.2"
            xmlSpace="preserve"
            width={props.width}
            height={props.height}
            className={props.className}
        >
            <path
                d="M1670 331.4H765.5v1439.7H47.9v-711.2h171.8v534.3h365V154.7h1263.2v352.7h-178c.1-59.4.1-116.5.1-176zM1847.3 1049.5h-172.9V878.7h172.9v170.8z"
                className="st0"
            ></path>
        </svg>
    );
}
