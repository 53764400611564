import React from "react";

import { Moon } from "../../assets/icons/Moon/Moon";
import { Sun } from "../../assets/icons/Sun/Sun";
import { ToggleOn } from "../../assets/icons/Toogle/ToggleOn";
import { ToggleOff } from "../../assets/icons/Toogle/ToggleOff";
import "./styles.scss"
export const DarkToggle2 = (props) => {
    return (
        <div className="row noSpace my-auto mx-0 mx-lg-4  px-1 px-lg-3 py-1 py-md-2 ml-3 mr-2">
            <Sun className="my-auto" width="30" height="30" fill="white"/>
            {!props.isDarkModeEnabled ?
                <button className="btn my-auto" onClick={()=>props.updateThemeMode(true)}>
                    <ToggleOff width="50" height="40" />
                </button>
                :
                <button className="btn my-auto" onClick={()=>props.updateThemeMode(false)}>
                    <ToggleOn width="50" height="40"/>
                </button>
                
            }
            
            <Moon className="my-auto" width="24" height="24" fill="white" />

        </div>

    );
};