import React from "react";

export const ToggleOn = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.width}
            height={props.height}
            className={props.className}
            x="0"
            y="0"
            version="1.1"
            viewBox="0 0 2202 1168"
            xmlSpace="preserve"
        >
            <g transform="translate(-86.235 -51.12)">
                <path
                    fill="#103440"
                    d="M1259.082 633.716c.7-249.4 197.3-444 447.6-443.3 243.6.8 443.7 201.4 443.7 444.8 0 248.1-201.4 446.4-452.6 445.6-244.7-.8-439.4-199.2-438.7-447.1zm442.3-375.7c-208 .4-381.9 174.8-378.4 379.5 3.6 209.9 173 376.5 382.1 375.7 209.3-.8 377.8-170.3 377.1-379.5-.6-208.3-170.7-376.1-380.8-375.7z"
                    className="st1"
                ></path>
                <path
                    fill="#00acbd"
                    d="M1189.582 122.316c170.8.3 341.6-1.1 512.3 1.4 304.1 4.4 527.8 241.9 515.1 544.3-10.4 248.7-227.8 473.2-477.6 477-365.8 5.6-731.9 4.9-1097.8-1.4-250.1-4.4-461.3-213.6-482.7-461.9-22.1-255.7 149.9-494.1 397.7-547.2 45.6-9.8 93.4-12.2 140.2-12.5 164.3-1.3 328.5-.1 492.8.3zm69.5 511.4c-.7 246.4 193.7 443.6 438 444.4 250.8.8 451.9-196.3 451.8-442.9.1-241.9-199.8-441.4-443-442.1-249.9-.8-446.1 192.8-446.8 440.6z"
                    className="st2"
                ></path>
            </g>
        </svg>
    );
}

