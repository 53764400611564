import React from 'react'
import CountUp from "react-countup"
export const CountUpBlock=(props)=> {
        

    return (
        <CountUp
            start={0}
            end={props.end}
            duration={5}
            delay={0}
            separator=" "
            decimals={0}
            decimal=","
            prefix={props.prefix}
            suffix={props.suffix}


        >
            {({ countUpRef }) => (
                <div className="py-3 py-lg-4" style={{ backgroundColor: props.background }}>
                    <h5 className="mb-0 gotham-rounded-medium">{props.title}</h5>
                    {props.icon}
                    <br />
                    <h4 className="mb-0 gotham-rounded-medium" ref={countUpRef} />
                    {props.button}
                </div>
            )}
        </CountUp>
    )
}
