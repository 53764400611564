import React from "react";

export const LexisNews=(props)=> {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            enableBackground="new 0 0 2267.7 1884.2"
            version="1.1"
            viewBox="0 0 2267.7 1884.2"
            xmlSpace="preserve"
            width={props.width}
            height={props.height}
            className={props.className}
        >
            <path
                d="M42 88h1959.5v272.4h228.4v14.8c0 393 .1 786-.1 1179-.1 120-83 220.1-201 243.4-15.7 3.1-32 4.4-48.1 4.4-585 .2-1170 .2-1755.1.1-103.6 0-183.5-78.5-183.6-181.1-.1-506.8 0-1013.6 0-1520.3V88zm1868.2 1624.4v-1534H132.7v16.5c0 472.5.4 944.9-.6 1417.4-.1 57.8 40 100.8 101.9 100.7 552.7-1.1 1105.5-.7 1658.2-.7 5.7.1 11.4.1 18 .1zm92.7-1261.2V1711c6.2-1.1 11.3-1.8 16.3-3 71.4-17.3 119.9-79.5 119.9-155.6.2-362.9.1-725.8.1-1088.7 0-4.1-.5-8.1-.8-12.5h-135.5z"
                className="st0"
            ></path>
            <path
                d="M815.4 898.9H270.9V269.1h544.5v629.8zm-90.6-90V359H361.5v449.9h363.3zM270.6 1169.1v-87.8h1456.8v87.8H270.6zM1727.8 1307.3v87.8H271v-87.8h1456.8zM270.5 1620.4v-87.7h1456.8v87.7H270.5zM1000.1 356.7v-87.8h727.3v87.8h-727.3zM1000 627.4v-87.7h727.3v87.7H1000zM1727.9 810.9v87.6h-727.3v-87.6h727.3z"
                className="st0"
            ></path>
        </svg>
    );
}

