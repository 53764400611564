import React from "react";

export const LHL=(props)=>{
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width={props.width} height={props.height}
            className={props.className}
            x="0"
            y="0"
            version="1.1"
            viewBox="0 0 500 420"
            xmlSpace="preserve"
        >
            <g transform="translate(1.45 58.4)">
                <path
                    fill="#229dac"
                    d="M78.2 -44.5H487V15.899999999999999H78.2z"

                ></path>
                <path
                    fill="#229dac"
                    d="M10.4 38.5H419.2V98.9H10.4z"

                ></path>
                <path
                    fill="#229dac"
                    d="M78.2 121.4H487V181.8H78.2z"

                ></path>
                <path
                    fill="#229dac"
                    d="M10.4 204.3H419.2V264.7H10.4z"

                ></path>
                <path
                    fill="#229dac"
                    d="M78.2 287.3H487V347.7H78.2z"

                ></path>
                <path
                    fill="#26495b"
                    d="M10 -44.5H70.4V15.899999999999999H10z"

                ></path>
                <path
                    fill="#26495b"
                    d="M10 287.3H70.4V347.7H10z"

                ></path>
                <path
                    fill="#26495b"
                    d="M10 121.4H70.4V181.8H10z"

                ></path>
                <path
                    fill="#26495b"
                    d="M426.7 38.5H487.09999999999997V98.9H426.7z"

                ></path>
                <path
                    fill="#26495b"
                    d="M426.7 204.3H487.09999999999997V264.7H426.7z"

                ></path>
            </g>
            <image
                width="1050"
                height="700"
                x="-7942"
                y="-7882.896"
                overflow="visible"
                xlinkHref="D48CBC0F.jpg"
            ></image>
            <path fill="none" d="M10 351.9H664.2V488.2H10z"></path>
        </svg>
    );
}

