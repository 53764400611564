import React from "react";

export const ToggleOff = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.width}
            height={props.height}
            className={props.className}
            x="0"
            y="0"
            version="1.1"
            viewBox="0 0 2202 1168"
            xmlSpace="preserve"
        >
            <g transform="translate(315.079 -238.417)">
                <path
                    fill="#103440"
                    d="M2483.41 1855.75c.6 249.4-195.1 445-445.4 445.5-243.6.5-444.7-199.2-445.9-442.6-1.3-248.1 199.2-447.4 450.4-447.8 244.7-.5 440.3 197 440.9 444.9zm-440.4 377.9c208-1.5 381-176.7 376.5-381.4-4.7-209.9-174.9-375.6-384-373.8-209.3 1.9-376.9 172.2-375.2 381.4 1.7 208.3 172.6 375.2 382.7 373.8z"
                    className="st1"
                    transform="translate(-1769.297 -1032.198)"
                ></path>
                <path
                    fill="#929393"
                    d="M789.014 1334.253c-170.8 1.5-341.6 4.7-512.3 4-304.1-1.2-530.3-236.4-520.8-538.9 7.8-248.8 222.8-475.5 472.6-482 365.7-9.4 731.8-12.6 1097.8-10 250.1 1.8 463.5 208.7 487.5 456.8 24.8 255.4-144.8 495.6-391.9 551.3-45.5 10.3-93.3 13.2-140.1 13.9-164.3 3-328.5 3.5-492.8 4.9zm-74.9-510.7c-1.9-246.4-198.3-441.6-442.6-439.8-250.8 1.8-449.8 201-447.2 447.6 2.5 241.9 204.4 439.2 447.6 437.5 249.9-1.9 444.1-197.5 442.2-445.3z"
                    className="st2"
                ></path>
            </g>
        </svg>
    );
}
