import "./set-public-path";
import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import Root from "./root.component";


import '@ionic/react/css/core.css';

//-------------- export components from ui library ------------------------
//export component to toggle to dark/night mode
export { DarkToggle } from './components/ToggleThemeMode/index';
export { DarkToggle2 } from './components/ToggleThemeMode2/index';

//export a custom title
export { SectionTitle } from './components/SectionTitle/index';

//export an animated  counter
export { CountUpBlock } from "./components/CountUp/index"


//export svg icons
export { LColor } from "./assets/icons/L/LColor"
export { LHL } from "./assets/icons/LHL/LHL"
export { Z1 } from "./assets/icons/Z1/Z1"
export { Z1Label } from "./assets/icons/Z1/Z1Label"
export { Lexis } from "./assets/icons/Lexis/Lexis"
export { Bell } from "./assets/icons/Bell/Bell"
export { UserGuide } from "./assets/icons/Userguide/UserGuide"
export { Academia } from "./assets/icons/Academia/Academia"
export { HerramientasJuridicas } from "./assets/icons/HerramientasJuridicas/HerramientasJuridicas"
export { LegVigente } from "./assets/icons/LegislacionVigente/LegVigente"
export { LexisEnRedes } from "./assets/icons/LexisEnRedes/LexisEnRedes"
export { LexisEntertainment } from "./assets/icons/LexisEntertainment/LexisEntertainment"
export { LexisNews } from "./assets/icons/LexisNews/LexisNews"
export { MisNotas } from "./assets/icons/MisNotas/MisNotas"
export { RO } from "./assets/icons/RO/RO"


//export images
import LexA1 from "./assets/images/lexa/cara-lexa.png"
export const LexA = LexA1
//---------------export components from external libraries------------------
//export icons from react-icons
export { GiBookmarklet } from "react-icons/gi";
export { FaPencilAlt, FaTrash, FaUsers, FaBookReader, FaLandmark, FaStar, FaRegStar, FaSearch, FaLink, FaBell, FaBook, FaHome, FaShareAlt, FaEdit, FaAngleDown, FaUserLock } from "react-icons/fa";
export { FaFacebook, FaTwitter } from "react-icons/fa";
export { FaPlay, FaPause, FaStop, FaAngleDoubleRight, FaAngleDoubleLeft, FaAngleLeft, FaAngleRight } from "react-icons/fa";
export { RiLogoutBoxRLine } from "react-icons/ri"
export { GiEvilBook, GiChessKing, GiChessQueen, GiChessPawn } from "react-icons/gi"
export { IoMdAdd } from "react-icons/io"
export { IoVolumeHigh } from "react-icons/io5"
export { ImWhatsapp } from "react-icons/im";
export { RiInstagramFill } from "react-icons/ri"
export { SiGooglecalendar, SiGoogledrive, SiGooglehangoutsmeet } from "react-icons/si"
export { FiLogOut } from "react-icons/fi"
export { TiThMenu } from "react-icons/ti"

//export ionic components
export {IonToggle, IonButton, IonIcon, IonInput, IonSegmentButton, IonSegment, IonSelect, IonSelectOption, IonItem, IonLabel, IonTextarea  } from "@ionic/react"
export { searchSharp, trashSharp } from 'ionicons/icons';

//export animations
import Fade1 from 'react-reveal/Fade';
export const Fade=Fade1
//export timeline component
export { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

// export phone number from input
export { isValidPhoneNumber } from 'react-phone-number-input'
import PhoneInput1 from 'react-phone-number-input/input'
export const PhoneInput = PhoneInput1

//export custom tooltip
import ReactTooltip1 from "react-tooltip";
export const ReactTooltip = ReactTooltip1

//export copy to clipboard helper
export { CopyToClipboard } from "react-copy-to-clipboard";

// export navigation components
export { NavLink, Redirect, BrowserRouter, Switch, Route, Link, useLocation } from "react-router-dom";

//export device type helpers
export { isMobile, isMobileOnly, isBrowser, deviceType, browserName } from 'react-device-detect';

//export text to speech component
export { useSpeechSynthesis } from 'react-speech-kit';

//export custom dropdown
import Select1 from 'react-select'
import makeAnimated1 from 'react-select/animated';
export const Select = Select1
export const makeAnimated = makeAnimated1

// export custom modal
import Modal1 from 'react-bootstrap/Modal';
export const Modal = Modal1

//export custom alert
import Alert1 from 'react-bootstrap/Alert';
export const Alert = Alert1

//export custom accordion
import Accordion1 from 'react-bootstrap/Accordion';
export const Accordion = Accordion1

//export custom card
import Card1 from 'react-bootstrap/Card';
export const Card = Card1

//export a custom collapse for accordion component
import Collapse1 from 'react-bootstrap/Collapse'
export const Collapse = Collapse1

//export a custom table with filters
import DataTable1 from 'react-data-table-component';
export const DataTable = DataTable1

//export custom file input with drag and drop feature
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone1 from 'react-dropzone-uploader'
export const Dropzone = Dropzone1

// export tree view
import Tree1 from '@naisutech/react-tree'
export const Tree = Tree1

//export helpers for scrolling
export { scroller, Element } from "react-scroll"

//export hooks for local storage
export { writeStorage } from '@rehooks/local-storage';


const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  errorBoundary(err, info, props) {
    // Customize the root error boundary for your microfrontend here.
    return null;
  },
});

export const { bootstrap, mount, unmount } = lifecycles;
