import React from "react";

export const Lexis=(props)=>{
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            x='0'
            y='0'
            width={props.width}
            height={props.height}
            enableBackground='new 0 0 2267.7 808'
            version='1.1'
            viewBox='0 0 2267.7 808'
            xmlSpace='preserve'
            className={props.className}
        >
            <path
                d='M999 271.8v57.1h179V414h-177.3v62.4h201.6v86.2H890.6V181.3h306.9v90.5H999zM1851.7 565h-99.3V188.5h99.3V565z'
                className='st0'
            ></path>
            <path
                d='M1291.8 188.5L1408.7 371.1 1283 562.4 1408.7 565 1471.5 444.4 1541.2 562.4 1669.2 562.4 1538.9 371.1 1658.9 188.5 1542.3 188.5 1476.1 294.7 1406.4 188.5z'
                className='st0'
            ></path>
            <path
                d='M1966.5 501.2c2.9-5 40.2-51.9 92.5-31.1 23.3 9.3 50.2 12.6 75.4 12.3 10.1-.1 25.4-15.9 28.1-27 1.9-7.9-11.2-24.8-21.3-29.3-26.7-11.9-55.6-18.9-83.4-28.4-57.4-19.6-80.7-51-78.3-104.4 2.3-50.6 35.6-87.7 90.4-100.1 63.1-14.2 117.6 4.7 170.1 43.6-14.4 20.5-27.1 38.7-39.2 56-24.6-9.3-46.2-19.5-69-25.3-13.5-3.4-29.6-2.7-42.9 1.5-8.9 2.8-20.1 14.3-20.9 22.7-.8 8.5 8 21.8 16.3 26.3 16.6 9.1 35.8 13.5 53.8 20 19.2 6.9 39.1 12.2 57.2 21.1 36.5 17.9 57 46.2 55.8 89.1-1.2 42.3-17.7 74-55.8 93.7-67.5 34.7-159.6 18.9-228.8-40.7z'
                className='st0'
            ></path>
            <path
                d='M2005.5 451L2056.2 469.1 2005.5 528.9 1966.5 501.2z'
                className='st0'
            ></path>
            <path
                d='M578.3 477.2H391.2V183.7H292l-.1 384.9h286.5v-91.4h-.1zm193.9 289.2h-746v-746h745.9l.1 746z'
                className='st0'
            ></path>
        </svg>
    );
}

