import React from "react";

export const LegVigente=(props)=> {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            enableBackground="new 0 0 2267.7 1884.2"
            version="1.1"
            viewBox="0 0 2267.7 1884.2"
            xmlSpace="preserve"
            width={props.width}
            height={props.height}
            className={props.className}
        >
            <path
                d="M2048.1 1478.8V145.4c-4.6-2.3-6.8-4.2-9.2-4.4-28.8-1.8-57.7-3.9-86.6-5-273.2-10.3-517.1 67.9-730.1 242-20 16.3-25.4 32.9-25.4 57.1.6 411.2.4 822.3.4 1233.5 0 12.3 1.3 24.7 2.3 41.1 254.1-192.2 536.5-261.1 848.6-230.9zm-962.3 230.3c1.5-8.8 2.7-12.5 2.7-16.2.1-423.1.3-846.2-.8-1269.2 0-14.2-10.1-32.5-21.3-41.9-206.2-173-445.2-251.8-712.6-246.5-39 .8-77.9 4-115.9 6.1V1479c311.5-30.6 594 38.1 847.9 230.1zm-308.1-77.4c-8.6-7-10.4-9.1-12.5-10.1-5.9-2.7-11.9-5.3-18.1-7.5-163.1-58.2-331.5-65.2-501.6-49.9-89.4 8-94.2 5.5-94.2-86V312v-37.5c-38 5-71.2 9.3-104.3 13.7v1335.6c242.8-28.5 483.2-33.8 730.7 7.9zM2135.5 275v1222c0 63.9-10.9 71.3-74.1 70-106.2-2.1-212.9-6.6-318.4 1.8-77.8 6.2-154.1 31.5-231.1 48.3.9 4.6 1.7 9.2 2.6 13.8 240.7-41.5 482.1-35.4 724.7-7.3V287.8c-34-4.2-66.3-8.2-103.7-12.8z"
                className="st0"
            ></path>
        </svg>
    );
}

