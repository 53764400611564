import React from "react";

export const LexisEnRedes=(props)=> {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            enableBackground="new 0 0 2267.7 1884.2"
            version="1.1"
            viewBox="0 0 2267.7 1884.2"
            xmlSpace="preserve"
            width={props.width}
            height={props.height}
            className={props.className}
        >
            <circle cx="267.3" cy="905" r="243" className="st0"></circle>
            <circle cx="1152.4" cy="905" r="243" className="st0"></circle>
            <circle cx="2006.4" cy="905" r="243" className="st0"></circle>
        </svg>
    );
}

