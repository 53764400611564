import React from "react";

export const LColor=(props)=> {
    return (
        <svg width={props.width} height={props.height} className={props.className} version="1.1" viewBox="0 0 25 25">
            <g transform="translate(0 -272)">
                <g>
                    <g
                        fillOpacity="1"
                        stroke="none"
                        strokeDasharray="none"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        strokeOpacity="1"
                        transform="translate(-1.619 -36.849) scale(1.12952)"
                    >
                        <path
                            fill="#12adbd"
                            strokeWidth="0.1"
                            d="M1.843 273.876H23.158V295.12399999999997H1.843z"
                        ></path>
                        <path
                            fill="#143642"
                            strokeWidth="0.088"
                            d="M3.146 275.112H21.855V293.88800000000003H3.146z"
                        ></path>
                        <g fill="#fff" transform="translate(-.262 .849)">
                            <path
                                strokeWidth="0.1"
                                d="M8.82 278.239H11.292V289.063H8.82z"
                            ></path>
                            <path
                                strokeWidth="0.121"
                                d="M8.82 286.525H16.704V289.06399999999996H8.82z"
                            ></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

