import React from "react";

export const MisNotas=(props)=> {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            enableBackground="new 0 0 2267.7 1884.2"
            version="1.1"
            viewBox="0 0 2267.7 1884.2"
            xmlSpace="preserve"
            width={props.width}
            height={props.height}
            className={props.className}
        >
            <path
                d="M1597.6 266.5c66.9 66.1 132.8 131.3 198.2 195.9 14.1-14.4 28.4-29 43.5-44.5-64.4-63.2-130.6-128.2-197.9-194.3 15.2-15 27.9-27.4 40.5-39.8 44.9-44.6 89.8-89.1 134.7-133.7 20.3-20.2 41.5-20.4 61.7-.2 47.3 47.3 94.5 94.7 141.6 142.2 20 20.2 19.7 41.3-.7 61.6-53.1 53-106.3 105.9-159.5 158.7-2.3 2.3-5.1 4-8.2 6.5 3 3.6 4.8 6.2 6.9 8.4 36.9 37.1 73.8 74.1 110.8 111.2 19.8 19.8 19.7 41.6-.4 61.6-118.5 117.8-236.9 235.6-355.4 353.3-29 28.9-57.8 58-87.2 86.5-22.6 21.9-58.5 12.3-65.4-17.2-3.7-15.6 1.7-28.3 13-39.5 66.3-65.6 132.3-131.4 198.5-197.2 70.2-69.7 140.3-139.5 210.5-209.2 2.3-2.3 4.4-4.7 6.2-6.5-31.3-30.9-62.3-61.5-94.3-93.1C1416 853.8 1035.9 1232 657.2 1608.7c-68.6-67.6-136.6-134.7-205.3-202.4C834 1026.2 1215.7 646.4 1597.6 266.5zM414.2 1461.6c62.6 61.8 124.5 122.9 187.7 185.3-130.5 68-260 135.4-389.5 202.8-.7-.5-1.4-.9-2.1-1.4 68.1-129.1 136.2-258.2 203.9-386.7z"
                className="st0"
            ></path>
        </svg>
    );
}
