import React from "react";

export const Bell = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 1734.2 1701.3"
      version="1.1"
      viewBox="0 0 1734.2 1701.3"
      xmlSpace="preserve"
      width={props.width}
      height={props.height}
      className={props.className}
    >
      <path d="M236.1 1381.7c-10.8-45 .6-77.3 33.1-106.1 36.7-32.6 82.3-65.2 99.2-108.2 17.4-44.3 5.4-101.2 5.6-152.6.3-81.7-.3-163.4.2-245.1 1.1-207 126.8-383.6 317.6-443.8 25.3-8 29.1-21.1 29.4-45.2 1.1-88.4 63.4-140.2 136.7-115.8 43.2 14.4 70.4 54.5 69.9 112.4-.3 29.5 7.7 41.1 35.9 50.2 182.6 58.3 309.7 237.3 311.2 435.5.9 125.3-.5 250.7 1.1 376 .2 16.6 8.8 36.1 19.5 48.9 26.1 31.2 53.9 61.6 84.5 87.8 33.1 28.2 43.9 61.1 33.1 106h-1177zM686.5 1460h274.3c.9 74.6-55.2 135.8-127.8 141.3-73.7 5.7-136.9-53.6-146.5-141.3z"></path>
    </svg>
  );
}


