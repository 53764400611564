import React from "react";

export const Academia=(props)=> {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            enableBackground="new 0 0 2267.7 1884.2"
            version="1.1"
            viewBox="0 0 2267.7 1884.2"
            xmlSpace="preserve"
            width={props.width}
            height={props.height}
            className={props.className}
        >
            <path
                d="M35.4 690.9c66.8-26.3 129.5-51 192.2-75.8C524.1 498 820.8 381 1117.2 263.5c17-6.8 31.4-7.3 48.6-.5 356.5 141.1 713.2 281.7 1069.8 422.5 3.3 1.3 6.3 3.5 11.2 6.3-124.6 49.1-247.1 97.5-369.7 145.9-239.3 94.5-478.5 189.2-718 283.2-9.2 3.6-22 4.7-30.9 1.2C765.1 979.3 402.4 835.9 35.4 690.9z"
                className="st0"
            ></path>
            <path
                d="M455.8 877.4c57.3 22.6 112.3 44.3 167.3 66.1 169.6 67.1 339.2 134.3 509 200.9 6.5 2.5 15.8 1.2 22.6-1.5 221.5-87.1 442.9-174.6 664.3-262 2-.8 4.2-.9 8.1-1.7.4 7 1.1 13.4 1.1 19.9.1 126.2-.2 252.5.4 378.7.1 16.1-4.6 26.1-17.7 35.5-110.7 80-234.8 127.1-366 156.4-140.1 31.3-281.9 39.5-424.5 27.2-178.9-15.3-350-59-505.2-155.4-59.5-37-59.3-37.5-59.3-108.1V902.6c-.1-7.4-.1-14.8-.1-25.2zM2122.2 763.4c0 121.2-.3 240.4.5 359.6.1 10.7 5.6 22.3 11.4 31.7 9.4 15.3 12.4 29.8 5.4 47.1-4.3 10.8-7.4 22.9-7.5 34.4-.7 58.5-.3 116.9-.3 177.2h-62.9v-48.2c0-40.3.7-80.5-.4-120.8-.4-15.3-7.2-30.6-6.9-45.8.2-15.6 5-31.4 8.8-46.8 2.4-9.6 9.5-18.6 9.6-27.9.7-103.3.5-206.6.5-309.9-.1-39.1-.2-39.1 41.8-50.6z"
                className="st0"
            ></path>
        </svg>
    );
}

