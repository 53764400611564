import React from "react";

export const HerramientasJuridicas=(props)=> {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            enableBackground="new 0 0 2267.7 1884.2"
            version="1.1"
            viewBox="0 0 2267.7 1884.2"
            xmlSpace="preserve"
            width={props.width}
            height={props.height}
            className={props.className}
        >
            <path
                d="M30.7 978.8C71.5 879.4 112 780 153.1 680.7c34.7-83.9 70.2-167.6 105.1-251.4 23.2-55.7 45.9-111.6 68.8-167.4 2.1-5.2 3.7-10.5 6.4-18.2-9.2-.6-16.3-1.4-23.4-1.4-35.1-.1-70.2.2-105.3.1-32.7-.1-55.5-13.8-65.2-38.7-9.6-24.9-2.4-55.9 22.1-73.9 11.5-8.4 28.8-12.1 43.6-12.3 107-1 214.1-.6 321.1-.7 319.4 0 638.8 0 958.2-.1 192.8 0 385.6-.1 578.4.1 48.7.1 82 38.3 70.9 80.2-7.5 28.3-30.6 45-63.6 45.2-32.2.2-64.5 0-96.7 0h-35.8c2.8 8.4 4.4 14.2 6.7 19.6 57.1 137.5 114.3 275 171.3 412.5 34.6 83.3 67.4 167.5 104.1 249.9 23 51.5 20.9 103 10.6 155.3-11.3 57.3-38.6 107.5-72.6 154.9-139.3 194.2-443.5 217.4-612.1 19.1-62.7-73.7-96.9-161.3-96.1-259.8.1-13.9 4.4-28.5 9.7-41.5 34.2-83.5 69.4-166.7 104.1-250 38.4-92.3 76.7-184.7 114.9-277.2 22.4-54.2 44.4-108.5 66.5-162.8 2.1-5.2 3.8-10.5 6.5-18.2-8-.6-14-1.3-19.9-1.3-170.3-.2-340.7-.3-511-.3-23.7 0-24 .1-24 23.4 0 260.7.1 521.4.1 782.1 0 207.8-.1 415.5-.2 623.3 0 27.1 0 27.2 27.2 27.2 110.5-.1 221-.3 331.5-.1 12 0 24.4.6 35.8 3.9 24.8 7.2 41.5 31.8 41.2 57.7-.3 27.9-17.9 53-43.1 60.2-8.7 2.5-18.1 3.5-27.2 3.5-284.3.2-568.6.2-853 .1-48.4 0-79.8-38.9-66.8-81.5 7.6-25 26.2-41.6 52.6-42.7 40.2-1.6 80.5-1.3 120.8-1.3 80-.1 160-.2 240 .5 15.1.1 19.8-4.3 19.8-19.5-.4-258.4-.4-516.8-.4-775.2 0-210.1.2-420.1 0-630.2 0-35.7 3.7-31.4-31.4-31.4-166.9 0-333.8.1-500.7.2h-22.9c2.2 7.2 3.3 12 5.2 16.5C580.4 392.9 636 526.7 691.5 660.5c40.7 98.2 81.8 196.3 121.9 294.8 16 39.2 8.3 79.6.1 119-12 57.6-37.7 109-71.7 157.3-137.1 195.3-444.4 222.8-615.4 20.8C77.2 1194.3 45 1126 34.8 1049c-.8-6.2-2.8-12.2-4.3-18.3.2-17.4.2-34.7.2-51.9zM1845 340c-81.6 197.3-161.7 391.1-242.6 586.7h485.3c-81-195.8-161.1-389.3-242.7-586.7zM184.1 926.4h485.1c-81-195.8-161-389.3-242.5-586.4C345 537.4 265 730.9 184.1 926.4z"
                className="st0"
            ></path>
        </svg>
    );
}
