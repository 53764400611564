import React from "react";

export const Moon = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.width}
            height={props.height}
            className={props.className}
            fill={props.fill}
            x="0"
            y="0"
            version="1.1"
            viewBox="0 0 1126 1129"
            xmlSpace="preserve"
        >
            <g  transform="translate(-3926.507 -44.293)">
                <path
                    
                    d="M4340.2 45c-109.5 231.2-90.7 446 90.1 626.1 180 179.3 394.2 196.9 621.7 89.2-41 180.8-238.9 407-538.9 412.2-293.5 5.1-546.6-216-581.8-508.3-36.5-301.9 165.3-551.6 408.9-619.2z"
                    className="st3"
                ></path>
            </g>
        </svg>
    );
}
