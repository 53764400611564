import React from "react";

export const Z1=(props)=> {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.width}
            height={props.height}
            className={props.className}
            version="1.1"
            viewBox="0 0 30.5 30.5"
            
        >
            <g transform="translate(0 -266.5)">
                <path
                    strokeWidth="0.027"
                    d="M.25 281.75v-15h30v30h-30zm26.838-.013v-11.825H3.412v23.649h23.676zm-14.946 2.5v-2.946h5.892v5.891h-5.892zm6.27 0v-2.946h5.892v5.891h-5.892zm-12.486-6.325v-2.946h5.865v5.892H5.926zm6.216 0v-2.946h5.892v5.892h-5.892z"
                ></path>
            </g>
        </svg>
    );
}


