import React from "react";

export const Sun = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.width}
            height={props.height}
            className={props.className}
            fill={props.fill}
            x="0"
            y="0"
            version="1.1"
            viewBox="0 0 1185 1171"
            xmlSpace="preserve"
        >
            <g transform="translate(-84.218 -24.928)">
                <path

                    d="M677.4 930.3c-179.7.3-324.1-142.4-324.2-320.3-.1-175.7 144.4-319.7 321-320 182.3-.2 325.7 141.7 325.8 322.3.2 175.3-144.4 317.8-322.6 318z"
                    className="st0"
                ></path>
                <path

                    d="M633.6 1102.4c0-15.2-.2-30.4 0-45.6.4-25.1 20.3-45.7 44.8-47 25.7-1.4 48.8 16.6 49.9 42.4 1.5 34.4 1.4 69.1-.3 103.5-1.2 24.3-24 41.2-49.3 40-24.2-1.1-44.2-20.3-45.1-43.9-.5-16.5 0-33 0-49.4z"
                    className="st0"
                ></path>
                <path

                    d="M346.7 345.5c-5.4-2.8-17.3-6.3-25.6-13.8-22.6-20.3-43.8-42.2-65.1-63.8-20.6-20.9-20.8-48.2-1-67.8 19.6-19.3 47.9-18.9 68.3.9 21.5 20.9 43.4 41.5 64.5 62.7 14.5 14.6 17.7 36.4 9.1 54.9-8.7 18.6-24.3 25.8-50.2 26.9z"
                    className="st0"
                ></path>
                <path

                    d="M1108.2 228.6c-4.9 9.6-8 20.9-15.1 28.4-21.6 23.1-44.3 45.2-67.2 67-19.9 18.9-50.5 18.2-68.8-.5-17.7-18-16.5-47.7 3.1-67.5 20.8-21 41.7-42 63-62.5 15.5-14.8 34-17.6 53.7-9.8 17.9 7.1 28.8 24.3 31.3 44.9z"
                    className="st0"
                ></path>
                <path

                    d="M1001.7 873.2c9.9 5.1 21.5 8.5 29.5 15.7 23 20.5 44.8 42.3 66.5 64.2 17.3 17.5 19.2 43.5 5.4 62-14 18.9-38.9 25.2-59.4 14.8-4-2-7.8-4.6-11.1-7.7-22.8-21.9-45.7-43.8-68-66.1-14-14-17.3-34.4-9.7-51.2 8.2-18 26-29.3 46.8-31.7z"
                    className="st0"
                ></path>
                <path

                    d="M177.7 662c-15.2 0-30.4.2-45.6-.1-27-.5-46.9-20.5-47-46.8-.1-26 20-47.1 46.5-47.5 31.6-.5 63.2-.5 94.8 0 25.6.4 46.4 21.7 46.8 46.4.4 25.7-19.7 46.9-46.3 47.9-16.4.6-32.8.1-49.2.1z"
                    className="st0"
                ></path>
                <path

                    d="M1174.8 558.6c15.6 0 31.2-.2 46.8.1 25.3.5 45.8 20.4 46.7 44.9 1 25.2-18.2 48.3-43.5 49.2-33.6 1.2-67.4 1.3-101 0-25.8-1-44.4-23.5-43.5-49 .9-25 20.5-44.2 46.4-45 16-.6 32.1-.3 48.1-.2z"
                    className="st0"
                ></path>
                <path

                    d="M408.9 935.4c-2.9 5.7-6.4 17.7-13.9 25.9-21 23.1-43.3 44.9-65.7 66.7-20.3 19.7-48.6 18.8-68.2-1.5-18.4-19-17.9-46.2 1.6-66.1 21-21.4 41.8-43 63.4-63.8 15.3-14.8 34-18.2 53.6-9.9 18.4 7.9 27.5 22.9 29.2 48.7z"
                    className="st0"
                ></path>
                <path

                    d="M718.6 118.6c0 15.2.3 30.4-.1 45.6-.5 26.8-19.7 46.3-45.5 46.8-26.6.5-47.8-18.5-48.5-45.3-.8-31.2-.9-62.4-.2-93.6.6-27.7 21.5-47.3 48.3-47 25.9.2 45.4 20.4 45.9 47.9.4 15.2.2 30.4.1 45.6z"
                    className="st0"
                ></path>
            </g>
        </svg>
    );
}

