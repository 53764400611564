import React from "react";

export const Z1Label=(props)=> {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            enableBackground="new 0 0 880.2 981"
            version="1.1"
            viewBox="0 0 880.2 981"
            xmlSpace="preserve"
            width={props.width}
            height={props.height}
            className={props.className}
            style={props.style}
        >
            <path
                d="M354.3 416.2H516.9V578.8H354.3z"
                className="st0"
                transform="rotate(-90 435.58 497.5)"
            ></path>
            <path
                d="M535.3 416.2H697.9V578.8H535.3z"
                className="st0"
                transform="rotate(-90 616.58 497.5)"
            ></path>
            <path
                d="M856 837H24.2V5.2H856V837zm-744.6-87.2h657.4V92.4H111.4v657.4z"
                className="st0"
            ></path>
            <path
                d="M174.3 233.5H336.9V396.1H174.3z"
                className="st0"
                transform="rotate(-90 255.58 314.837)"
            ></path>
            <path
                d="M354.3 233.5H516.9V396.1H354.3z"
                className="st0"
                transform="rotate(-90 435.58 314.837)"
            ></path>
            
            <path
                d="M376.8 945.1l42-47.2h-40.5v-19.6h72.6v16.9l-42 47.2h42V962h-74v-16.9zM474.9 899.4l-14.1 3.2-4.4-17.9 24.5-6.9h16.9V962H475v-62.6z"
                className="st0"
            ></path>
        </svg>
    );
}
